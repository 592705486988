<template>
  <div v-if="_g_IsUserLogged" id="help-center">
    <div v-if="_g_UserFeatures.permissions.help_center">
      <div class="help-center">
        <h3>¿Necesitas Ayuda?</h3>
      
        <div class="container-margin">
          <div class="helper-description" :class="is_mobile_device ? 'subtitle-mobile-container' : 'subtitle-desktop-container'">
            Despliega las categorías de videos de ayuda para ver todas las temáticas y los videos disponibles sobre el uso de la web.
          </div>

          <br>
          <br>
        
        <div v-if="!is_mobile_device" id="autocomplete-container" class="autocomplete-container">
          <div id="autocomplete" class="autocomplete">
            <SearchAutocomplete
              :items="obj_data_all_videos_title"
              @input_value="_showVideo"
            ></SearchAutocomplete>
          </div>
          <div v-if="clear_text" id="clear-input-container" class="clear-input-container">
            <button class="clear-button" @click="_clear_input()" aria-label="Clear alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
          <div> 
            <div v-if="is_mobile_device" class="mobile-select-button" @click="_openVideosForMobile">
              <span v-if="videos_menu_show == false" class="material-icons-outlined">list</span>
              <span v-else class="material-icons-outlined">close</span>
              <div class="video-guide-title-container">
                Guía de vídeos
              </div>
            </div>
            <div class="help-categories-contanier" :class="videos_menu_show == false ? 'mobile-hide' : 'mobile-not-hide'">

              <div v-for="(select, i) in select_options" :key="i">
                <div class="category-title" @click="_getValueFromSelectHelperCenter(select.value)">
                  <div class="category-title-icon">
                    <span v-if="value_selected == select.value" class="nav-link-icon material-icons-outlined">expand_more</span>
                    <span v-else class="nav-link-icon material-icons-outlined">chevron_right</span>
                  </div>
                  <div class="category-title-text">
                    {{ select.value }} 
                  </div>
                </div>
                <div v-if="value_selected == select.value">
                  <div v-for="(data, j) in obj_data_all_videos" :key="j">
                    <div v-if="data.category_id == select.name">
                        <div :class="data.url == last_iframe_selected ? 'video-selected' : 'video-not-selected'" class="category-video-title" @click="_getValueForIframeVideo(data.url, data.title)"> {{ data.title }} </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            
            <div class="video-container">
              <div class="iframe-video-container">
                <div v-if="is_mobile_device" class="title-video-selected-container">
                  {{ title_video_selected }}
                </div>
                <div v-if="iframe_url != ''">
                  <iframe id="player1" :src="iframe_url" width="100%" :height="video_height" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>                  
                <div v-else>
                  <div v-if="is_mobile_device == false">No hay ningún video seleccionado.</div>
                </div>
              </div>
            </div>         
          </div>
        </div>
      </div>
    </div>
    <div v-else class="not-allowed-help-center-container">
      <p class="not-allowed-help-center-title"> SIN ACCESO </p>
      <p class="not-allowed-help-center"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store" class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
    </div>
  </div>
  <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone" v-else style="text-align: center;">
    <div class="container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">       
      <img src="@/views/assets/help-center-screenshot.png">
      <div class="text-centeR">
        <h2>Centro de ayuda</h2>
        <p>El Centro de Ayuda de Eurekers es un recurso valioso que te permitirá encontrar respuestas a tus preguntas y soluciones a tus problemas de una manera rápida y sencilla. Desde allí podrás acceder a tutoriales, guías, y artículos que te ayudarán a sacar el máximo provecho de los servicios y productos ofrecidos por Eurekers. Además, podrás contactar al equipo de soporte técnico para recibir ayuda personalizada y resolver cualquier duda o inconveniente que puedas tener.</p>
      </div>
      <div class="button-container">
        <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
      </div> 
    </div>        
  </div>
</template>


<script>
//import Search from '@/components/helpers/Search.vue';
import SearchAutocomplete from "@/components/helpers/Autocomplete.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import Button from '../components/helpers/Button.vue';

export default {
  mixins:[APICaller],
  name: 'HelpCenter',
  components: {
    SearchAutocomplete
  },
  data() {
    return {
      property: 'property',
      helper_ref: 'helperRef',
      label_name: 'Seleccionar',
      select_options: [],
      obj_data_all_videos: [],
      obj_data_all_videos_title: [],
      obj_data_all_videos_json: [],
      obj_data: {},
      obj_data_search: [],
      filterItems: {},
      input_search: '',
      value_selected: '',
      iframe_url: '',
      video_height: 0,
      videos_menu_show: false,
      last_iframe_selected: '',
      title_video_selected: '',
      clear_text: false,
      input_ID: "",
    };
  },
  
  created(){
    this._openHelpCenterVideoByDefault();
  },

  mounted() {
    this._getInfoHelper();
    if (this.is_mobile_device) {
      this.videos_menu_show = false;
    }
    else {
      this.videos_menu_show = true;
    }
  },  
  computed: {
    ...mapGetters(['_g_IsUserLogged', "is_mobile_device", "_g_UserFeatures", "_g_User"]),
  },
  methods: {
    _redirectToLogin() {
        window.location.href = "/user/login"; // Reemplaza la URL con la que desees redirigir
    },

    _openVideosForMobile() {
      if (this.videos_menu_show == true) {
        this.videos_menu_show = false;
      }
      else {
        this.videos_menu_show = true;
      }

      this.iframe_url = '';
      this.title_video_selected = '';
    },

    _getValueFromSelectHelperCenter(value) {
      if (this.value_selected != value) {
        this.value_selected = value;
      }
      else {
        this.value_selected = '';
      }
    },
    _getValueForIframeVideo(url, title) {
      this._goToVideoLocation();
      this.iframe_url = url;
      this.last_iframe_selected = url;
      this.title_video_selected = title;

      if (this.is_mobile_device) {
        this.videos_menu_show = false;
      }

      setTimeout(() => {
        var iframe_width = document.getElementById('player1').clientWidth;
        this.video_height = iframe_width / 1.7;
      }, 150);
    },

    _goToVideoLocation(){
      var videoExists = setInterval(() => {
        if(document.getElementById("player1") != undefined || document.getElementById("player1") != null){
          window.scrollTo(document.body.scrollHeight, 0);
          clearInterval(videoExists);
        }
      }, 250);
    },

    _openHelpCenterVideoByDefault(){
      this._getValueForIframeVideo('https://player.vimeo.com/video/139562965?api=1&player_id=player1', 'Centro de Ayuda');
    },

    _getInfoHelper(){
      let success = response =>{
        this.obj_data = response.data.content;
        this.select_options = [];

        Object.entries(this.obj_data).forEach(item => {
          Object.entries(item[1]).forEach(element => {
            this.obj_data_all_videos.push(element[1]);
          });
        });

        this._ArrayBuilder();
        this._JSONBuilder();

        Object.entries(response.data.categories).forEach((item) => {
          this.select_options.push({name: item[0], value: item[1]});
        });
      }

      let url = '/api/frontend/v1/support-page'

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('184', 'Error recuperando información de soporte.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _JSONBuilder(){

      this.obj_data_all_videos.forEach((e) => {
        this.obj_data_all_videos_json.push({titulo: e.title , url: e.url, categoria: e.category});
      });

    },

    _ArrayBuilder(){

      for(let i = 0; i < this.obj_data_all_videos.length; i++){
        this.obj_data_all_videos_title.push(this.obj_data_all_videos[i].title);
      }

    },

    _showVideo(value){
      this.clear_text = true;
      if(this.obj_data_all_videos_json != null || this.obj_data_all_videos_json != undefined || this.obj_data_all_videos_json > 0){
        
        for(let i = 0; i < this.obj_data_all_videos_json.length; i++){
          if(value == this.obj_data_all_videos_json[i].titulo){
            this._getValueFromSelectHelperCenter(this.obj_data_all_videos_json[i].categoria);
            this._getValueForIframeVideo(this.obj_data_all_videos_json[i].url, this.obj_data_all_videos_json[i].titulo);
          }
        }
      }

    },

    _clear_input(){
      document.getElementsByTagName("input")[0].value = "";
      this.clear_text = false;
      this.value_selected = "";
      this.iframe_url = "";
    }

  }
}
</script>



<style lang="scss" scoped>
  @use "@material/select/styles";
  @use '@material/select';

  html, body, #app {
    background-color: red !important;
  }

  .help-center {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: var(--color-background);
  }

  .container-margin {
    width: 95%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .helper-description, .category-video-title {
    color: var(--color-text-primary);
  }

  h3 {
    margin: 50px 30px 25px;
    color: var(--color-text-primary);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .help-categories-contanier {
    width: 100%;
    float: left;
  }

  .mobile-select-button {
    width: 80%;
    margin: 0 9.5% 20px;
    float: left;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    padding: 15px 0;
    background-color: var(--color-highlight);
    color: var(--color-text-primary);
    border-radius: 30px;
  }

  .mobile-select-button span {
    font-size: 14px;
    margin: 0 10px;
  }

  .video-container {
    width: 100%;
    float: left;
    margin: 5px 0 20px;
  }

  .iframe-video-container {
    width: 95%;
    margin: 0 2%;
  }

  .category-title {
    font-size: 16px;
    font-weight: bolder;
    cursor: pointer;
    padding: 10px;
    margin-top: 5px;
    background-color: var(--color-surface);
    color: var(--color-text-primary);
    border-radius: 5px;
    border-bottom: 1px solid var(--color-outline);
  }

  .category-title span {
    font-size: 16px;
  }

  .category-video-title {
    font-size: 14px;
    cursor: pointer;
    border: 1px solid var(--color-outline);
    border-top: none;
    padding: 20px 10px 20px 20px;
  }

  .category-title-icon {
    display: inline;
    width: 25%;
  }

  .category-title-text {
    display: inline;
    width: 74%;
  }

  .video-selected {
    font-weight: bolder;
  }

  .mobile-hide {
    display: none;
  }

  .mobile-select-button div,
  .mobile-select-button span {
    display: inline;
  }

  @media (min-width: 840px) {
    h3 {
      margin: 50px 30px 25px;
    }

    .help-categories-contanier {
      width: 25%;
      margin-bottom: 20px;
    }

    .video-container {
      width: 74%;
    }
  }

  .clear-input-container {
    margin-left: 20px;
  }

  .clear-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 25px;
    color: var(--color-text-primary);
  }

  .autocomplete-container {
    display: flex;
    margin-bottom: 5px;
  }

  .video-selected:hover, 
  .video-not-selected:hover {
    color: var(--color-accent-secondary);
  }

  .video-guide-title-container {
    margin-left: 20px;
  }

  .title-video-selected-container {
    text-align: center;
    font-weight: bold;
  }

  .subtitle-mobile-container {
    margin-left: 25px;
  }

  .container-image {
    margin: 0 auto;
    max-width: 800px;
    max-height: auto;
  }

  .container-image img {
    max-height: 800px;
    margin: 20px;
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) {
    .container-image img {
      max-width: 700px;
      width: 700px;
    }
  }

  @media screen and (max-width: 768px) {
    .container-image img {
      max-width: 315px;
    }
  }

  .text-centeR {
    text-align: center;
    margin: 20px;
  }

  .container-image p {
    text-align: justify;
  }

  .login-button {
    color: var(--color-text-primary);
    background-color: var(--color-highlight);
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }

  .login-button:hover {
    background-color: var(--color-accent-secondary);
  }

  .not-allowed-help-center-container {
    text-align: center;
    margin-top: 50px;
  }

  .link-to-store {
    color: var(--color-highlight) !important;
  }

  .not-allowed-help-center-title {
    color: var(--color-warning);
    font-weight: bold;
  }
</style>

